import React from 'react';
import { inject } from 'mobx-react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
  Chip,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper
} from '@mui/material';
import { Link } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import theme from '@extensions/services/Theme';
import { DatasetSummary as DapDatasetSummary } from '@dapclient/components/dataset/DatasetSummary';
import DatasetModel from '@extensions/models/Dataset';
import LicenseLogo from '@extensions/resources/cc-zero.png';
import ViewDataPane from '@extensions/components/dataset/ViewDataPane';
import ViewDataPlotPane from '@extensions/components/core/ViewDataPlotPane';

const StyledValue = styled(Typography)(() => ({
  color: theme.palette.text.primary,
}));

const StyledLabelAndValue = styled('div')(() => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const StyledLicenseLogo = styled('img')(() => ({
  height: '1.3rem',
}));

export class DatasetSummary extends DapDatasetSummary {
  getPanel = ({
    ariaControls,
    id,
    title,
    body,
  }: {
    ariaControls: string;
    id: string;
    title: string;
    body: React.ReactNode;
  }) => (
    <Accordion component={Paper}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={ariaControls}
        id={id}
      >
        <Typography variant="h3">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{body}</AccordionDetails>
    </Accordion>
  );

  labelValuePair = (
    label: React.ReactNode,
    value: React.ReactNode | string | number | (string | number)[]
  ) => {
    return (
      <StyledLabelAndValue>
        <Typography variant="h4">
          {label}
        </Typography>
        {Array.isArray(value) && !React.isValidElement(value) ? (
          (value as (string | number)[]).map((item) => (
            <StyledValue variant="body2">
            {item || 'N/A'}
          </StyledValue>
          ))
        ) : (
          <StyledValue variant="body2">
            {value || 'N/A'}
          </StyledValue>
        )}
      </StyledLabelAndValue>
    );
  };

  combineCategory = (
    category: string[],
    subCategory: string[],
  ): string[] => {
    if (subCategory === null || subCategory === undefined) {
      return category;
    }
    const combinedArray = category.map(catItem => {
      const matchingSubCat = subCategory.find(subCatItem => subCatItem.includes(catItem));
      return matchingSubCat || catItem;
    });
    return combinedArray;
  };

  renderLicense = (value: string) => {
    return (
      <StyledLabelAndValue>
        <Typography variant="h4">
          License
        </Typography>
        {value && value.indexOf('creativecommons') > -1 ? (
          <span>
            <a href="/license">
              <StyledLicenseLogo
                src={LicenseLogo}
                alt="license-logo"
              />
            </a>
          </span>
        ) : (
          <StyledValue variant="body2">
            {value || 'N/A'}
          </StyledValue>
        )}
      </StyledLabelAndValue>
    );
  };

  getMapExplanation = () => null;

  getPrettyList<T>({
    value,
    singletonToStr = (singleton) => singleton,
  }: {
    value: T | T[] | undefined | null;
    singletonToStr?: (T) => string;
  }): string[] | undefined {
    if (value === null || value === undefined) {
      return undefined;
    }
    if (Array.isArray(value)) {
      return value.map((singleton) => singletonToStr(singleton));
    }
    return [singletonToStr(value)];
  }

  renderGridItem = (buildingStat, label) => {
    if (buildingStat) {
      return (
        <Grid item xs={12} sm={6}>
          {this.labelValuePair(label, buildingStat)}
        </Grid>
      )
    }
    return null;
  }

  getBuildingCharacteristicsPanel = (dataset: DatasetModel | null): React.ReactNode => {
    if (!dataset) {
      return null;
    }
    const location = dataset.buildingLocation;
    const buildingStats = dataset.buildingCharacteristics;
    return this.getPanel({
      title: 'Building Characteristics',
      id: 'building-characteristics-header',
      ariaControls: 'building-characteristics-content',
      body: (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <StyledLabelAndValue>
              {
                location
                  ? location.anonymized
                    ? this.labelValuePair(
                      'Location',
                      this.getPrettyList({
                        value: location.state,
                        singletonToStr: (state) =>
                          `${state}`,
                      })
                    )
                    : this.labelValuePair(
                      'Location',
                      this.getPrettyList({
                        value: location,
                        singletonToStr: (singleLocation) =>
                          `${singleLocation.city}, ${singleLocation.state} ${singleLocation.zipCode}`,
                      })
                    )
                  : this.labelValuePair(
                    'Location',
                    undefined
                  )
              }
            </StyledLabelAndValue>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledLabelAndValue>
              {
                location
                  ? location.anonymized
                    ? this.labelValuePair(
                      'IECC Climate Zone',
                      this.getPrettyList({
                        value: location.climateZone,
                        singletonToStr: (climateZone) =>
                          `${climateZone}`,
                      })
                    )
                    : this.labelValuePair(
                      'IECC Climate Zone',
                      this.getPrettyList({
                        value: location,
                        singletonToStr: (singleLocation) => singleLocation?.climateZone,
                      })
                    )
                  : this.labelValuePair(
                    'IECC Climate Zone',
                    undefined
                  )
              }
            </StyledLabelAndValue>
          </Grid>
          {this.renderGridItem(buildingStats.type, 'Building Type')}
          {this.renderGridItem(buildingStats.totalFloorArea, 'Total floor area (sq. ft)')}
          {this.renderGridItem(buildingStats.name, 'Name')}
          {this.renderGridItem(buildingStats.yearBuilt, 'Built Year')}
          {this.renderGridItem(buildingStats.wallRvalue, 'Wall Assembly R-value ((h⋅ft2⋅°F)/BTU)')}
          {this.renderGridItem(buildingStats.ceilingRvalue, 'Ceiling R-Value ((h⋅ft2⋅°F)/BTU)')}
          {this.renderGridItem(buildingStats.foundationRvalue, 'Foundation R-Value')}
          {this.renderGridItem(buildingStats.windowUfactor, 'Window U-Factor (BTU/(h⋅ft2⋅°F))')}
          {this.renderGridItem(buildingStats.numberFloors, 'Number of Floors')}
          {this.renderGridItem(buildingStats.numberPeople, 'Number of People')}
          {this.renderGridItem(buildingStats.additionalInfo, 'Additional Information')}
        </Grid>
      ),
    });
  };

  getSystemCharacteristicsPanel = (dataset: DatasetModel | null): React.ReactNode => {
    if (!dataset) {
      return null;
    }
    return this.getPanel({
      title: 'System Characteristics',
      ariaControls: 'system-characteristics-content',
      id: 'system-characteristics-header',
      body: (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {this.labelValuePair(
              'HVAC System Type',
              dataset.systemCharacteristics.hvacSystemType
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {this.labelValuePair(
              'Fuel Type',
              dataset.systemCharacteristics.fuelType
            )}
          </Grid>
        </Grid>
      ),
    });
  };

  getDataDetails = (dataset: DatasetModel | null): React.ReactNode => {
    if (!dataset) {
      return null;
    }
    return this.getPanel({
      title: 'Data Details',
      ariaControls: 'data-details-content',
      id: 'data-details-header',
      body: (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {dataset.dataFilesDescription &&
              this.labelValuePair(
                'Data Files Description',
                dataset.dataFilesDescription
              )}

            {dataset.timeline && (
              <div style={{ display: 'flex' }}>
                <div style={{ width: '50%' }}>
                  {this.labelValuePair(
                    'Data Begin',
                    dataset.timeline.start.format('YYYY-MM-DD')
                  )}
                </div>

                {this.labelValuePair(
                  'Data End',
                  dataset.timeline.end.format('YYYY-MM-DD')
                )}
              </div>
            )}
            {this.labelValuePair('Categories', this.combineCategory(dataset.dataCategory, dataset.dataSubCategory))}
            {this.labelValuePair(
              'Data Formats',
              dataset.dataFormats.join(', ')
            )}
            {this.renderLicense(dataset.license)}
            {dataset.doi &&
              this.labelValuePair(
                'Digital Object Identifier (DOI)',
                dataset.rawDataDoi ? (
                  <>
                    <div>
                      <Typography variant="subtitle1" component="span">
                        Processed Data:
                      </Typography>{' '}
                      {dataset.doi}
                    </div>
                    <div>
                      <Typography variant="subtitle1" component="span">
                        Raw Data:
                      </Typography>{' '}
                      {dataset.rawDataDoi}
                    </div>
                  </>
                ) : (
                  dataset.doi
                )
              )}
          </Grid>
        </Grid>
      ),
    });
  };

  getReferencesPanel(dataset: DatasetModel | null): JSX.Element | null {
    if (dataset?.references && dataset.references.length) {
      return this.getPanel({
        title: 'References',
        id: 'dataset-summary-reference',
        ariaControls: 'dataset-summary-reference',
        body: (
          <TableContainer component={Paper} style={{ maxHeight: '500px' }}>
            <Table size={'medium'} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Reference Title
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  dataset.references
                    .slice()
                    .map((reference) => {
                      return (
                        <TableRow key={reference.referenceURL}>
                          <TableCell>
                            <Link to={reference.referenceURL}>{reference.referenceTitle}</Link>
                          </TableCell>
                        </TableRow>
                      )
                    })
                }
              </TableBody>
            </Table>
          </TableContainer>

        )
      })
    }
    return null;
  }

  getAdditionalPanels = (dataset: DatasetModel | null): React.ReactNode => {
    if (!dataset) {
      return null;
    }
    return (
      <>
        {this.getBuildingCharacteristicsPanel(dataset)}
        {this.getSystemCharacteristicsPanel(dataset)}
        {this.getDataDetails(dataset)}
      </>
    )
  };

  getPreviewSection = (dataset: DatasetModel): React.ReactNode | null => {
    let isViewDataPaneOn = false;
    let isViewDataPlotOn = false;
    const { user } = this.props.securityService;
    if (user && user.authenticated && user.emailVerified) {
      if (dataset.realtimeData && dataset.realtimeData.viewDataTable) {
        isViewDataPaneOn = true;
      }
      if (dataset.realtimeData && dataset.realtimeData.viewDataPlot) {
        isViewDataPlotOn = true;
      }
      const viewDataTab = {
        label: "VIEW DATA",
        value: "viewData"
      }
      const viewPlotTab = {
        label: "VIEW PLOT",
        value: "viewPlot"
      }
      let tabsArr: { label: string, value: string }[] = [];
      if (isViewDataPaneOn && isViewDataPlotOn) {
        tabsArr = [viewDataTab, viewPlotTab];
        return (
          <TabContext value={this.state.activeTab}>
            <TabList onChange={this.onTabsChange} aria-label="tabs list">
              {tabsArr.map((t, i) => (
                <Tab label={t.label} value={t.value} key={i} />
              ))}
            </TabList>
            <TabPanel value="viewData">
              <ViewDataPane dataset={dataset} />
            </TabPanel>
            <TabPanel value="viewPlot">
              <ViewDataPlotPane dataset={dataset} />
            </TabPanel>
          </TabContext>
        );
      } else if (isViewDataPaneOn) {
        tabsArr = [viewDataTab];
        return (
          <TabContext value={this.state.activeTab}>
            <TabList onChange={this.onTabsChange} aria-label="tabs list">
              {tabsArr.map((t, i) => (
                <Tab label={t.label} value={t.value} key={i} />
              ))}
            </TabList>
            <TabPanel value="viewData">
              <ViewDataPane dataset={dataset} />
            </TabPanel>
          </TabContext>
        );
      } else if (isViewDataPlotOn) {
        tabsArr = [viewPlotTab];
        return (
          <TabContext value={this.state.activeTab} >
            <TabList onChange={this.onTabsChange} aria-label="tabs list">
              {tabsArr.map((t, i) => <Tab label={t.label} value={t.value} key={i} />)}
            </TabList>
            <TabPanel value="viewPlot">
              <ViewDataPlotPane dataset={dataset} />
            </TabPanel>
          </TabContext>
        )
      }
      return null;
    }
  }
}

export default inject((store: any) => ({
  datasetService: store.datasetService,
  securityService: store.securityService,
  membershipService: store.membershipService,
  notificationService: store.notificationService,
  projectService: store.projectService,
  metricsService: store.metricsService,
}))(DatasetSummary);

